const swiperOption = {
    slidesPerView: "auto",
    spaceBetween: 5,
    freeMode: true,
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
    }
};

export { swiperOption };
