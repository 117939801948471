<template>
  <div class="floor-plan-design-details">
    <!-- Main Photo & Title -->
    <div class="mb-3">
      <cool-light-box
        :items="[`${$getFileURL(design.mainPhoto[0])}`]"
        :index="mainPhotoIndex"
        @close="mainPhotoIndex = null"
        :slideshow="false"
      >
      </cool-light-box>

      <div
        class="design-main-photo cursor-pointer"
        :style="{
          'background-image': `linear-gradient(to bottom, #00000010, #000000EE),
                  url(${$getFileURL(design.mainPhoto[0])})`
        }"
        @click="mainPhotoIndex = 0"
      >
        <div class="d-flex align-items-end h-100 p-2">
          <h3 class="fg-white m-2">{{ design.name }}</h3>
        </div>
      </div>
    </div>

    <div class="container fluid p-1 sm-p-2 md-p-3">
      <!-- Floor Plan Info -->
      <div class="bordered-card mb-4">
        <h5 class="p-2">Floor Plan Info</h5>
        <floor-plan-detail-header :floorPlan="design.floorPlan">
        </floor-plan-detail-header>
      </div>

      <!-- Gallery -->
      <div v-if="galleryImages.length > 0" class="mb-3">
        <cool-light-box
          :items="galleryImages"
          :index="galleryImageIndex"
          @close="galleryImageIndex = null"
          :slideshow="false"
        >
        </cool-light-box>
        <swiper class="swiper d-flex" :options="swiperOption">
          <swiper-slide
            v-for="(galleryImage, imageIndex) in galleryImages"
            :key="imageIndex"
            class="xl-col-2 lg-col-3 md-col-4 sm-col-4 col-6"
          >
            <div class="image-container mx-1">
              <div
                class="image cursor-pointer"
                @click="galleryImageIndex = imageIndex"
              >
                <img :src="galleryImage" @error="$setAltPhoto" loading="lazy" />
              </div>
            </div>
          </swiper-slide>
          <div class="swiper-button-prev" slot="button-prev"></div>
          <div class="swiper-button-next" slot="button-next"></div>
        </swiper>
      </div>
      <!-- Panorama -->
      <display-data label="3D Panorama">
        <div v-show="!isPanoramaLoading" class="d-block">
          <a class="btn my-1" target="_blank" :href="design.panoramaLink">
            <i class="fas fa-external-link-alt mr-1"></i> Open in New Tab
          </a>
        </div>
        <iframe
          v-show="!isPanoramaLoading"
          :src="design.panoramaLink"
          class="panaroma"
          allowfullscreen
          @load="isPanoramaLoading = false"
        ></iframe>
      </display-data>
      <div
        v-if="isPanoramaLoading"
        class="d-flex align-items-center justify-content-center"
        style="width: 600px; height: 400px"
      >
        <spinner class="d-block mr-2"></spinner>
        <p>Loading 3D Panorama...</p>
      </div>
      <!-- Renovation Fee -->
      <display-data
        v-if="Number(design.estimatePrice) !== 0"
        label="Renovation Cost/Fee"
      >
        RM {{ numberWithCommas(design.estimatePrice) }}
      </display-data>
      <!-- Design Concept -->
      <display-data v-if="design.description" label="Design Concept">
        {{ design.description }}
      </display-data>
    </div>
  </div>
</template>

<script>
import { numberWithCommas } from "@/utils/string";
import { swiperOption } from "@/utils/swiper";
export default {
  components: {
    DisplayData: () => import("@/components/GlobalComponents/DisplayData"),
    Spinner: () =>
      import("@/components/GlobalComponents/LoaderComponent/Spinner"),
    FloorPlanDetailHeader: () =>
      import("@/modules/Project/components/FloorPlan/Detail/Header")
  },
  mixins: [],
  props: {
    design: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      isPanoramaLoading: true,
      mainPhotoIndex: null,
      galleryImageIndex: null,

      numberWithCommas,
      swiperOption
    };
  },
  computed: {
    galleryImages() {
      let gallery = this.design.gallery.map((item) => {
        return this.$getFileURL(item);
      });
      return [...gallery];
    }
  },
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {}
};
</script>

<style lang="scss">
.floor-plan-design-details {
  .design-main-photo {
    border-radius: 0;
    width: 100%;
    height: 350px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    @media #{$breakpoint-down-sm} {
      height: 120px;
    }
    @media #{$breakpoint-down-md} {
      height: 250px;
    }
  }

  iframe.panaroma {
    width: 600px;
    height: 400px;

    @media #{$breakpoint-down-sm} {
      width: 100%;
      height: 160px;
    }
    @media #{$breakpoint-down-md} {
      width: 100%;
      height: 250px;
    }
  }

  .image-container {
    @include flex(row, center, center);
    position: relative;

    &:before {
      content: "";
      display: block;
      padding-top: 100%;
    }

    .image {
      display: block;
      width: 100%;
      img {
        @include image(cover);
        position: absolute;
        bottom: 0;
        left: 0;
        top: 0;
      }
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    opacity: 0.8;
    color: white;
  }
}
</style>
